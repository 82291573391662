import React, { useEffect, useState, useContext, useRef } from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import { Form, Button, Dropdown, FormControl, Image } from 'react-bootstrap';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import AmbaramLogo from './../../../assets/images/ambaram-logo.svg';
import search from './../../../assets/images/search.svg';
import User from './../../../assets/images/User.svg';
import userProfileIcon from './../../../assets/images/userProfileImage.svg';
import downarrow from './../../../assets/images/down-arrow.svg';
import userArrow from './../../../assets/images/userArrow.svg';
import projectIcon from './../../../assets/images/projectIcon.svg';
import ambaramCircleLogo from '../../../assets/images/ambaramCircleLogo.svg';
import share from '../../../assets/icons/wall-icon/shareNoti.svg';
import joinTeam from '../../../assets/icons/GroupUsers.svg';
import comment from '../../../assets/icons/comment.svg';
import teamInvitation from '../../../assets/icons/invitation.svg';
import { httpPost, httpGet, addThumbPrefix, } from './../../../utils/http';
import {
  AUTH_ENDPOINT,
  GLOBAL_SEARCH,
  TEAM,
  USER,
  SOCKET_ENDPOINT,
  STORAGE_BUCKET,
} from './../../../config/endPoints';
import profile from './../../../assets/images/profile.svg';
import settings from './../../../assets/images/settings.svg';
import roles from './../../../assets/images/roles.svg';
import help from './../../../assets/images/help.svg';
import signout from './../../../assets/images/signout.svg';
import MenuIcon from './../../../assets/images/Menu.svg';
import notification from './../../../assets/images/notification.svg';
import HeartIcon from './../../../assets/images/heart.svg';
import rightArrowBlue from './../../../assets/images/rightArrowBlue.svg';
import socketIOClient from 'socket.io-client';
import { NO_NOTIFICATIONS } from '../../../config/generalMessage';
import _ from 'lodash';
import { AppContext } from '../../../config/context';
import { permissionTypes } from '../../../config/const';
import PermissionsUpdated from '../../Modal/Delete/PermissionsUpdated';
import { infoToaster } from '../../Toaster';

function TopHeader() {
  /** Initial states */
  const wrapperRef = useRef(null);
  const navRef = React.useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const {
    setTeamApi,
    UserInfoContext,
    teamIdForPermission,
    permissionStatus,
    setPermissionStatus,
    globalSearchStatus,
    setGlobalSearchStatus,
  } = useContext(AppContext);
  const userInfoData = UserInfoContext;
  const defaultLocation = useLocation();
  const currentEndpoint = defaultLocation.pathname.substring(1);
  const [token, setToken] = useState();
  const [userDetail, setUserDetails] = useState();
  const [searchInput, setSearchInput] = useState('');
  const [expandSidebar, setExpandSidebar] = useState(false);
  const [notificationArray, setnotificationArray] = useState([]);
  const [newNotification, setNewNotification] = useState({});
  const [notificationObj, setNotificationObject] = useState({});
  const [notifcationList, setNotificationList] = useState([]);
  const [searchArr, setSearchArr] = useState([]);
  const [socketStatus, setSocketStatus] = useState(false);
  const [flag, setFlag] = useState(false);
  const [teamList, setTeamList] = useState([]);
  const [searchState, setSearchState] = useState(false);
  const [selectedPermissionData, setSelectedPermissionData] = useState({});
  const getUserDetails = JSON.parse(localStorage.getItem('userDetails'));
  const [showModal, setShowModal] = useState(false);
  const [permissionsMessage, setPermissionsMessage] = useState('');

  const handlePermissionsUpdated = () => {
    setShowModal(false);
    navigate(0);
  };

  /** verifyPermissions */
  const verifyPermissions = () => {
    let id = teamIdForPermission?._id;
    if (id && userDetail) {
      let index = userDetail?.teamRolePermissions?.findIndex(
        (item) => item.teamId === id
      );
      if (index != -1)
        setSelectedPermissionData(
          userDetail?.teamRolePermissions[index]?.permissionArr
        );
    }
  };

  /**openNav for search */
  const openNav = () => {
    setExpandSidebar(!expandSidebar);
    expandSidebar
      ? (document.getElementById('sidenav').className = 'SidebarWrapper toggle')
      : (document.getElementById('sidenav').className = 'SidebarWrapper');
    expandSidebar
      ? (document.getElementById('ContentWrap').className =
          'ContentWrap contentToggle')
      : (document.getElementById('ContentWrap').className = 'ContentWrap');
  };

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    verifyPermissions();
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, [teamIdForPermission, selectedPermissionData]);

  /** handleClickOutside */
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };
  /** useEffect to get teamlist */
  useEffect(() => {
    getTeamList();
    openNav();
  }, []);

  useEffect(() => {
    setUserDetails(userInfoData);
  }, [userInfoData]);

  useEffect(() => {
    if (!flag) {
      getUserNotification();
    }
    setUserDetails(JSON.parse(localStorage.getItem('userDetails')));
    const socket = socketIOClient(SOCKET_ENDPOINT);
    socket.on('notification', (data) => {
      if (data.body.key !== 'Team Permissions') {
        if (
          data.body.key == 'addExistingUserInTeam' ||
          data?.body?.key == 'joinTeamId'
        ) {
          getTeamList();
        }
        if (data) {
          let status = true;
          setFlag(true);
          setSocketStatus(status);
          setNewNotification(data);
          setNotificationStatus(true);
        }
      }

      if (data.body.key === 'Team Permissions') {
        setPermissionsMessage(data.body.message);
        setShowModal(true);
        setPermissionStatus(true);
        if (getUserDetails._id == data.body.user._id) {
          localStorage.setItem('userDetails', JSON.stringify(data.body.user));
        }
        // navigate(0);
        verifyPermissions();
      } else {
        setPermissionStatus(false);
      }
    });

    if (
      newNotification &&
      (getUserDetails?._id === newNotification?.id ||
        newNotification?.body?.key == 'Reminder') &&
      notificationStatus
    ) {
      let clone = [];
      setTeamApi(true);
      clone = [newNotification.body];
      setNotificationList((current) => [...clone, ...current]);
      setnotificationArray((current) => [...clone, ...current]);
      setNotificationStatus(false);
      // infoToaster(`You got a new notification on ${newNotification.body.key}`);
    }

    return () => {
      socket.off('notification');
    };
  }, [newNotification]);

  /**
   * logout
   */
  const logOut = async () => {
    let result = await httpPost(`${AUTH_ENDPOINT}/logout`);
    localStorage.removeItem('authorization');
    localStorage.removeItem('userDetails');
    if (result?.data?.responseCode == 200) {
      navigate('/');
      setToken();
    }
    let token = localStorage.getItem('authorization');
    if (!token || token == null) {
      navigate('/');
    }
  };

  /**getUserNotification */
  const getUserNotification = async () => {
    let result = await httpGet(`${USER}/userNotifications`);
    if (result?.data?.responseCode == 200) {
      setFlag(true);
      setNotificationObject(result.data);
      let clone = [];
      clone = [...result.data.data];
      setNotificationList((current) => [...clone, ...current]);
      const newNotificationCount = [...clone, ...notifcationList];
      if (newNotificationCount.length > 0) {
        const initialLogin = localStorage.getItem('initialLogin');

        if (initialLogin === 'true') {
          localStorage.setItem('initialLogin', 'false');
          infoToaster('You have unread notifications');
        }
      }

      if (!socketStatus) {
        setnotificationArray((current) => [...clone, ...current]);
      }
    }
  };

  /**
   * convert Time and Date
   */

  const convertTime = (time) => {
    let convertedTime = '';
    if (time) {
      convertedTime = moment(time).format('LLLL');
      return convertedTime;
    }
    return convertedTime;
  };
  /**
   * isNotified
   */

  const isNotified = async () => {
    let result = await httpPost(`${USER}/markasRead`);
    if (result.data.responseCode == 200) {
      setnotificationArray([]);
    }
  };

  /**
   * Mark as Read
   */
  const markasRead = async () => {
    setNotificationList([]);
  };

  /**
   * naviagte to Notifications
   */

  const navigateNotifications = () => {
    navRef.current.classList.remove('show');
    navigate('/notification');
  };

  /**
   * Handle Search Input
   */
  const handleChange = (value) => {
    setSearchInput(value);
    if (value.length >= 3) {
      setIsVisible(false);
      setSearchState(true);
      // getGlobalSearchData(value);
    }
  };

  /**
   * Global Search Result
   */
  const getGlobalSearchData = async (value) => {
    let apiUrl = `${GLOBAL_SEARCH}`;
    if (value) {
      apiUrl += `?searchFilter=${value}`;
    }
    let res = await httpGet(apiUrl);
    if (res.status == 200) {
      setSearchArr(res.data.data);
      navigate('/globalSearch', { state: res.data.data });
    }
  };

  /**createProductRoute */
  const createProductRoute = () => {
    navigate('/wall', { state: 'createProduct' });
  };

  /**
   * get user listing
   */

  const getTeamList = async () => {
    let sortBy = 'createdAt';
    let orderBy = 'createdAt';
    let teamRes = await httpGet(
      `${TEAM}/getUserTeamList?sortBy=${sortBy}&orderBy=${orderBy}`
    );
    if (teamRes?.status === 200) {
      setTeamList(teamRes?.data?.data);
    }
  };

  /**
   * Get particular team Users
   */
  const checkTeamUsers = async (team) => {
    let selectedTeamUser;
    let searchTeamUser = await httpGet(`${TEAM}/members/${team}`);
    if (searchTeamUser.status === 200) {
      selectedTeamUser = searchTeamUser.data;
    }
    let filterTeam = await teamList.filter((item) => {
      return item._id == team;
    });
    let teamDetails = {
      id: filterTeam[0]._id,
      name: filterTeam[0]?.name,
      createdBy: filterTeam[0]?.createdBy,
    };
    navigate(`/teamusers/${team}`, {
      state: { selectedTeamUser, teamDetails, teamList },
    });
  };

  /**
   * Get particular product detail
   */
  const productDetail = async (element) => {
    navRef.current.classList.remove('show');
    if (
      element.key == 'reqTeamId' ||
      element.key == 'joinTeamId' ||
      element.key == 'deleteTeamId'
    ) {
      navigate('/teams');
    }
    if (element.key == 'addExistingUserInTeam') {
      await checkTeamUsers(element.teamId);
    }
    if (element.key == 'joinTeamId') {
      await checkTeamUsers(element.teamId);
    }
    if (
      element.key == 'shareProduct' ||
      element.key == 'likeProduct' ||
      element.key == 'productComment'
    ) {
      navigate(`/productDetail/${element.productId}`, {
        state: { productId: element.productId },
      });
    }

    if (element.imgId && element.key == 'productComment') {
      let image = element.imgId.image;
      let imageId = element.imgId.image.split('/')[3];
      let imageName = element.imgId.image.split('/')[4];
      let productDetail = {
        _id: element.productId,
        name: element.imgId.productName,
      };
      let obj = {
        productDetail: productDetail,
        image: image,
        imageName: imageName,
      };
      navigate(`/productImgDetail/${imageId}`, { state: obj });
    }
  };

  /**
   * Route to Dashboard
   */
  const changeRoute = () => {
    navigate('/dashboard');
  };

  useEffect(() => {
    if (!_.isEmpty(searchInput) && searchState) {
      getGlobalSearchData(searchInput);
      setSearchState(false);
    }
  }, [searchInput]);

  return (
    <>
      <header>
        <div className="header-content">
          <Button
            className="navbar-toggle desktophide"
            data-toggle="collapse"
            id="menu-toggle-2"
            onClick={openNav}
          >
            <img src={MenuIcon} title="" />
          </Button>
          <div className="logo-wrap" onClick={changeRoute}>
            <img src={AmbaramLogo} />
          </div>
          <div className="SearchWrap mobilehide">
            <Form>
              <FormControl
                ref={wrapperRef}
                type="text"
                placeholder="Search"
                className="mr-sm-2"
                name="search"
                onClick={() => setIsVisible(true)}
                value={searchInput}
                onChange={(e) => handleChange(e.target.value)}
              />
              <Button className="searchBtn searcIcon">
                <img src={search} />
              </Button>
            </Form>
          </div>

          <div className="user-wrap">
            <div className="userImg notificationDrop">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <img src={notification} onClick={isNotified} />
                  <span className="notificationCount">
                    {notificationArray?.length}
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu ref={navRef}>
                  <div className="notificationScroll">
                    {Array.isArray(notifcationList) &&
                    notifcationList.length != 0 ? (
                      notifcationList.map((item, index) => {
                        return (
                          <div
                            className="d-flex notiWrap"
                            onClick={() => productDetail(item)}
                            key={index}
                          >
                            <div className="imgWrap">
                              {item.key == 'shareProduct' ? (
                                <span className="notiImage">
                                  {' '}
                                  <img src={share} alt="" />
                                </span>
                              ) : item.key == 'likeProduct' ? (
                                <span className="notiImage">
                                  <img src={HeartIcon} alt="" />
                                </span>
                              ) : item.key == 'joinTeamId' ||
                                item.key == 'addExistingUserInTeam' ? (
                                <span className="notiImage">
                                  <img src={joinTeam} alt="" />
                                </span>
                              ) : item.key == 'productComment' ? (
                                <span className="notiImage">
                                  <img src={comment} alt="" />
                                </span>
                              ) : item.key == 'reqTeamId' ? (
                                <span className="notiImage">
                                  <img src={teamInvitation} alt="" />
                                </span>
                              ) : (
                                <span className="notiImage">
                                  <img src={ambaramCircleLogo} alt="" />
                                </span>
                              )}
                            </div>
                            <div className="notiContent" key={index}>
                              <h4>{item.message}</h4>
                              <p>
                                {item.createdTime
                                  ? convertTime(item.createdTime)
                                  : ''}
                              </p>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="d-flex notiWrap justify-content-center">
                        <div className="notiContent">
                          <h4>{NO_NOTIFICATIONS} </h4>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="notibtn">
                    <button
                      onClick={navigateNotifications}
                      className="btnNoti-all"
                    >
                      Show All notifications
                      <img src={rightArrowBlue} alt="" />
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div className="userImg marginLeft">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <a className="CreateBtn">
                    Create <img src={downarrow} />
                  </a>
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  {(teamIdForPermission == '' ||
                    teamIdForPermission == undefined ||
                    (selectedPermissionData &&
                      selectedPermissionData[permissionTypes?.ADD_PRODUCT]) ||
                    userDetail?._id === teamIdForPermission?.createdBy) && (
                    <Dropdown.Item
                      // as={Link}
                      onClick={() => createProductRoute()}
                      // to={"/createProduct"}
                      className={
                        currentEndpoint == 'wall/createproduct' ? 'active' : ''
                      }
                    >
                      <span className="InnerIcon">
                        <img src={projectIcon} />
                      </span>{' '}
                      Create Product
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item
                    as={Link}
                    to={'/Teams'}
                    className={currentEndpoint == 'Teams' ? 'active' : ''}
                  >
                    <span className="InnerIcon">
                      <img src={projectIcon} />
                    </span>{' '}
                    Create Team
                  </Dropdown.Item>

                  <Dropdown.Item
                    as={Link}
                    to={'/wall/reminders'}
                    className={
                      currentEndpoint == 'wall/reminders' ? 'active' : ''
                    }
                  >
                    <span className="InnerIcon">
                      <img src={projectIcon} />
                    </span>{' '}
                    Create Reminder
                  </Dropdown.Item>

                  {/* <Dropdown.Item href="#">
                  <span className="InnerIcon">
                    <img src={CreateTask} />
                  </span>{" "}
                  Create task
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <span className="InnerIcon">
                    <img src={upload} />
                  </span>{" "}
                  Upload File
                </Dropdown.Item>
                <Dropdown.Item href="#">
                  <span className="InnerIcon">
                    <img src={event} />
                  </span>{" "}
                  Create event
                </Dropdown.Item>
                <Dropdown.Item href="#" >
                  <span className="InnerIcon">
                    <img src={folderGreyIcon} />
                  </span>{" "}
                  Create Folder
                </Dropdown.Item> */}
                  {/* <Dropdown.Item href="#">
                  <span className="InnerIcon">
                    <img src={contact} />
                  </span>{" "}
                  Create contact
                </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="userImg ">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  <Image
                    // src={
                    //   userDetail?.profilePicture
                    //     ? STORAGE_BUCKET.concat(userDetail?.profilePicture)
                    //     : userProfileIcon
                    // }
                    src={
                      userDetail?.profilePicture
                        ? `${STORAGE_BUCKET}${addThumbPrefix(userDetail?.profilePicture)}`
                        : userProfileIcon
                    }
                    width="36px"
                    height="36px"
                    roundedCircle
                  />
                  <img className="downarrow" src={userArrow} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="UserHead">
                    <Image
                      // src={
                      //   userDetail?.profilePicture
                      //     ? STORAGE_BUCKET.concat(userDetail?.profilePicture)
                      //     : userProfileIcon
                      // }
                      src={
                        userDetail?.profilePicture
                          ? `${STORAGE_BUCKET}${addThumbPrefix(userDetail?.profilePicture)}`
                          : userProfileIcon
                      }
                      width="36px"
                      height="36px"
                      roundedCircle
                    />
                    <span>
                      <h3>{userDetail?.firstName}</h3>
                      <p>{userDetail?.email}</p>
                    </span>
                  </div>
                  <Dropdown.Item as={Link} to="/setting/personal">
                    <span className="InnerIcon">
                      <img src={profile} />
                    </span>{' '}
                    Your Profile
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={Link}
                    to="/setting/company"
                    className={currentEndpoint == 'setting' ? 'active' : ''}
                  >
                    <span className="InnerIcon">
                      <img src={settings} />
                    </span>{' '}
                    Settings
                  </Dropdown.Item>

                  {userDetail?.planType != 'Free' && (
                    <Dropdown.Item as={Link} to="/setting/role">
                      <span className="InnerIcon">
                        <img src={roles} />
                      </span>{' '}
                      Roles & Permission
                    </Dropdown.Item>
                  )}

                  <Dropdown.Item as={Link} to="/HelpSupport">
                    <span className="InnerIcon">
                      <img src={help} />
                    </span>{' '}
                    Help & Support
                  </Dropdown.Item>
                  <Dropdown.Item onClick={logOut}>
                    <span className="InnerIcon">
                      <img src={signout} />
                    </span>{' '}
                    Sign Out
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </header>
      {isVisible && <div className="overlay"></div>}

      <PermissionsUpdated
        showDel={showModal}
        DelClose={handlePermissionsUpdated}
        title={permissionsMessage}
        //  Desc="This will delete your team, Are you sure?"
      />
    </>
  );
}

export default TopHeader;

import React from "react";
import { Button, Modal, CloseButton } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import closeicon from "../../../../assets/images/closeicon.svg";
import DeleBlueIcon from "../../../../assets/images/DeleBlueIcon.svg";
import './style.scss';

const PermissionsUpdated = ({
  showDel,
  DelClose,
  title,
  Desc,
}) => {
  let location = useLocation();
  return (
    <>
      
       <Modal show={showDel} onHide={DelClose} className="AddteamPopup">
        <Modal.Header>
          {/* <span className="ModalLogo">
            <img src={DeleBlueIcon} />
          </span> */}
          <Modal.Title>{title}</Modal.Title>
          {/* <CloseButton className="closebuttonbtn" onClick={DelClose}>
            <img src={closeicon} />
          </CloseButton> */}
        </Modal.Header>
        <Modal.Footer className="justify-content-center">
          <Button variant="primary" onClick={DelClose}>
            Ok
          </Button>
          {/* <Button variant="primary" >
            Delete
          </Button> */}
        </Modal.Footer>
      </Modal> 
    </>
  );
};
export default PermissionsUpdated;

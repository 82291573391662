import React from 'react'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.scss';
import crossIconWhite from './../../../assets/images/crossIconWhite.svg'
import logo from '../../../assets/images/ambaram-logo.svg'
import './../../../assets/css/main.scss';
import { Link } from "react-router-dom";
import "../../../App.css";

export const BASE_WEB_PATH = process.env.REACT_APP_BASE_URL;

function Header() {

  const openNav = () => {    
    document.getElementById("mySidenav").style.height = "300px";
  }

  const closeNav = () => {
    document.getElementById("mySidenav").style.height = "0";
  }

  const handleClick = () => {
    window.location.replace(`${BASE_WEB_PATH}/signup/`);
  };

  const handleClickForHome = () => {
    window.location.replace(`${BASE_WEB_PATH}`);
  };

  const handleClickForBlogs = () => {
    window.location.replace(`${BASE_WEB_PATH}/blog`);
  };

  const handleClickForAboutUs = () => {
    window.location.replace(`${BASE_WEB_PATH}/about-us/`);
  };

  return (
    <>
      <div className='header justify-content-between'>
      <Navbar.Brand href="#home">
            {/* <img src={logo} alt="Ambaram Logo"/> */}
            <h2>ambaram</h2>
           </Navbar.Brand>
        <Navbar bg="dark" variant="dark" expand="lg" className='justify-content-between'>
           {/* <Navbar.Brand href="#home">
            <img src={logo} alt="Ambaram Logo"/>
           </Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mr-auto my-2 my-lg-0"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              {/* <Nav.Link href="javascript:;">HOME</Nav.Link>
              <Nav.Link href="javascript:;">ABOUT US</Nav.Link>
              <Nav.Link href="javascript:;">BLOGS</Nav.Link>
              <Nav.Link href="javascript:;" as={Link} to="/plans">PLANS</Nav.Link>
              <Nav.Link as={Link} to="/">LOGIN</Nav.Link>
              <Nav.Link as={Link} to="/signup">SIGNUP</Nav.Link>  */}
              <Nav.Link as={Link} to="" onClick={handleClickForHome}>HOME</Nav.Link>
              <Nav.Link as={Link} to="" onClick={handleClickForAboutUs}>ABOUT US</Nav.Link>
              <Nav.Link as={Link} to="" onClick={handleClickForBlogs}>BLOGS</Nav.Link>
              <Nav.Link as={Link} to="/plans">PLANS</Nav.Link>
              {/* <Nav.Link as={Link} to="/">LOGIN</Nav.Link>
              <Nav.Link as={Link} to="/signup">SIGNUP</Nav.Link>  */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className='btnsGroup'>
          <Link className='resquestBtn' to="" onClick={handleClick}>
            REQUEST DEMO
            </Link>
          <Link to='/'>LOGIN</Link>
          <Link to='/signup'>SIGNUP</Link>
        </div>
        {/* Mobile Menu Start */}
        <div className='mobileMenu'>
        <span className='menuToggle' style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => openNav()}>&#9776;</span>
        <div id="mySidenav" className="sidenav">
          <div className='sadenavInner'>
            {/* <span className="closebtn" onClick={() => closeNav()}><img src={crossIconWhite}/></span>
            <Link  as={Link} to="https://www.ambaram.ai/">HOME</Link>
            <Link  as={Link} to="https://www.ambaram.ai/about-us/">ABOUT US</Link>
            <Link  as={Link} to="https://www.ambaram.ai/blog/">BLOGS</Link>
            <Link  as={Link} to="/">PLANS</Link>
            <Link className='loginLink'  as={Link} to="/">LOGIN</Link>
            <Link  className='SignupLink'  as={Link} to="/signup">SIGNUP</Link> */}
           
            <button className="closebtn" onClick={() => closeNav()}><img src={crossIconWhite}/></button>
            <Link to=''>HOME</Link>
            <Link to=''>ABOUT US</Link>
            <Link to=''>BLOGS</Link>
            <Link to=''>PLANS</Link>
            {/* <Link className='loginLink' to=''>LOGIN</Link>
            <Link className='SignupLink' to='/signup'>SIGNUP</Link> */}
          </div>
        </div>
        </div>
        {/* Mobile Menu End */}
      </div>
    </>
  )
}
export default Header
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
// import ReactDOM from 'react-dom/client';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter} from "react-router-dom";
import './i18n';

Bugsnag.start({
  apiKey: 'd53caf18226b59bb1f6fe5abf72c2241',
  plugins: [new BugsnagPluginReact()]
});
// BugsnagPerformance.start({ apiKey: 'd53caf18226b59bb1f6fe5abf72c2241' });
// Bugsnag.leaveBreadcrumb('App loaded');
// const ErrorBoundary = Bugsnag.getPlugin('react')
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorView = () =>
  <div>
    <p>Inform users of an error in the component tree.</p>
  </div>


const ErrorScreen = ({ clearError }) =>
  <div>
    <h1>⚠️ Error ⚠️</h1>
    <p><strong>Uh oh, there was an error in the component tree!</strong></p>
    <p>This <code>FallbackComponent</code> prop can be used to show something useful to your users when such errors occur.</p>
    <button onClick={clearError}>Reset</button>
  </div>

const onError = event => {
  // You can also provide an onError callback to run just on errors caught by
  // the error boundary. Maybe you want to attach some of the current state from
  // whatever model/store you're using (e.g redux)
  console.log('about to send this event', { event })
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <ErrorBoundary FallbackComponent={ErrorScreen} onError={onError}>
  <BrowserRouter>
    <App />
    </BrowserRouter>
    </ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { lazy } from 'react';
const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const Home = lazy(() => import('../pages/Home'));
const OTPVerification = lazy(() => import('../pages/OTPVerification'));
const CreatePassword = lazy(() => import('../pages/CreatePassword'));
const PasswordResetSuccess = lazy(() => import('../pages/PasswordResetSuccess'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Setting = lazy(() =>import('../pages/Setting'));
const Teams = lazy(() =>import('../pages/Teams'));
const CreateTeam = lazy(() =>import('../pages/Teams/CreateTeam'));
const TermsConditions = lazy(() =>import('../components/TermsOfService'));
const PrivacyPolicy = lazy(() =>import('../components/Privacy&Policy'));
const Questions = lazy(() =>import('../components/SetOfQuestions'));
const Cancellation = lazy(() =>import('../components/Cancellation'));
const JoinTeam = lazy(() =>import('../pages/Teams/JoinTeam'));
const TeamsList = lazy(() =>import('../pages/Teams/TeamsList'));
const CreateUser = lazy(() =>import('../pages/Teams/CreateUser'));
const TeamUsers = lazy(() =>import('../pages/Teams/TeamUsers'));
const Pricing = lazy(() =>import('../pages/Pricing'));
const ProductDetailLinkShare = lazy(() =>import('../pages/Products/ProductDetailLinkShare'));
const Categories = lazy(() =>import('../pages/Categories'));
const Tags = lazy(() =>import('../pages/Tags'));
const Seasons = lazy(() =>import('../pages/Seasons'));
const Fabrics = lazy(() =>import('../pages/Fabrics'));
const CreateProduct = lazy(() =>import('../pages/Products/CreateProduct'));
const ProductDetail = lazy(() =>import('../pages/Products/ProductDetail'));
const ViewProduct = lazy(() =>import('../pages/Products/ViewProducts'));
const Wall = lazy(() =>import('../pages/Wall'));
const Progress = lazy(() =>import('../pages/Wall/Progress'));
const Reminders = lazy(() =>import('../pages/Wall/Reminders'));
const ColorLibrary = lazy(() =>import('../pages/ColorLibrary'));
const ColorGrid = lazy(() =>import('../pages/ColorLibrary/ColorGrid'));
const HelpSupport = lazy(() =>import('../pages/HelpSupport'));
const Task = lazy(() =>import('../pages/Tasks'));
const GlobalTask = lazy(() =>import('../pages/Tasks/GlobalTask'));
const GlobalSearch = lazy(() =>import('../pages/GlobalSearch'));
const Notification = lazy(() =>import('../pages/Notification'));
const SearchResult = lazy(() =>import('../pages/SearchResult'));
const ProductImgDetail = lazy(() =>import('../pages/Products/ProductImgDetail'));

const authRoutes = [
  { path: '/signup', element: <Home/>},
  { path: '/', element: <Login/>},
  { path: '/forgotPassword', element: <ForgotPassword/>},
  { path: '/otpVerification', element: <OTPVerification/>},
  { path: '/createPassword', element: <CreatePassword/>},  
  { path: '/passwordResetSuccess', element: <PasswordResetSuccess/>},
  { path: '/termsOfService', element: <TermsConditions/>},
  { path: '/privacyPolicy', element: <PrivacyPolicy/>},
  { path: '/cancellation', element: <Cancellation/>},
  { path: '/plans', element: <Pricing/>},
  { path: '/questions', element: <Questions/>},
];

const publicRoutes = [
  {path: '/ProductDetailLinkShare/:productId/:teamId/:action', element: <ProductDetailLinkShare/>},
];

// After Login page  URL
const dashboardRoutes = [
  { path: '/dashboard', element: <Dashboard/>},
  { path: '/setting', element: <Setting/>},
  { path: '/teams', element: <Teams/>},
  { path: '/createteam', element: <CreateTeam/>},
  { path: '/jointeam', element: <JoinTeam/>},
  { path: '/teamslist', element: <TeamsList/>},
  { path: '/createuser', element: <CreateUser/>},
  { path: '/pricing', element: <Pricing/>},
  { path: '/categories', element: <Categories/>},  
  { path: '/teamusers/:id', element: <TeamUsers/>},
  { path: '/tags', element: <Tags/>},
  { path: '/seasons', element: <Seasons/>},
  { path: '/fabrics', element: <Fabrics/>},
  { path: '/wall/createproduct', element: <CreateProduct/>},
  { path: '/createproduct', element: <CreateProduct/>},
  { path: '/productDetail/:productId', element: <ProductDetail/>},
  { path: '/productDetail/:productId/:teamId', element: <ProductDetail/>},
  { path: '/viewproducts', element: <ViewProduct/>},
  { path: '/Wall', element: <Wall/>},
  { path: '/Wall/:id', element: <Wall/>},
  { path: '/Wall/:season/deadline/tag/category/fabric', element: <Wall/>},
  { path: '/Wall/Progress', element: <Progress/>},
  { path: '/wall/reminders', element: <Reminders/>},
  { path: '/ColorLibrary', element: <ColorLibrary/>},
  { path: '/ColorLibrary/ColorGrid', element: <ColorGrid/>},
  { path: '/setting/company', element: <Setting/>},
  { path: '/setting/personal', element: <Setting/>},
  { path: '/setting/email', element: <Setting/>},
  { path: '/setting/role', element: <Setting/>},
  { path: '/setting/notification', element: <Setting/>},
  { path: '/setting/team', element: <Setting/>},
  { path: '/HelpSupport', element: <HelpSupport/>},
  { path: '/Tasks', element: <Task/>},
  { path: '/Tasks/GlobalTask', element: <GlobalTask/>},
  { path: '/globalSearch', element: <GlobalSearch/>},
  { path: '/Notification', element: <Notification/>},
  { path: '/SearchResult', element: <SearchResult/>},
  { path: '/productImgDetail/:id', element: <ProductImgDetail/>},
];

export { authRoutes,dashboardRoutes, publicRoutes};
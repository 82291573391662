import { createSlice } from "@reduxjs/toolkit";

export const rerenderSlice = createSlice({
  name: "rerender",
  initialState:{value:false},
  //initialState: false,
  reducers: {
    toggle: (state) => {
      //state.value = !state.value;
      state =!state;
    },
    makeTrue: (state) => {
      //state.value = !state.value;
      state = true;
    },
    makeCustom: (state, action) => {
      state.value = action.payload.value;
      //state = action.payload.value;
    },
  },
});

export const { toggle, makeTrue, makeCustom } = rerenderSlice.actions;

export default rerenderSlice.reducer;

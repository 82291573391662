import React, { Suspense, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../../App.css";
import './style.scss';
import { useLocation, useNavigate, useParams, Navigate } from "react-router-dom";
import { AppContext } from "../../config/context";

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

function NonAuthLayout(props) {
  const { plansStatusWithoutLogin, setPlansStatusWithoutLogin } = useContext(AppContext);
  let location = useLocation();
  if (localStorage.getItem('authorization') && !plansStatusWithoutLogin) {
    return (
      <Suspense fallback={null}>
        <Navigate
          to={{ pathname: '/dashboard', state: { from: props.location } }}
        />
      </Suspense>
    );
  }

  return (
    <div className={`${((location.pathname == '/questions') || (location.pathname == '/refundPolicy') || (location.pathname == '/cancellation') || (location.pathname == '/plans') || (location.pathname == '/privacyPolicy') || (location.pathname == '/termsOfService')) ? "privacyPolicyWrapper" : "signupWrapper"}`}> 
    <div className="signupWrapper" >
      <Header />
      {props.element}
      {( props.router.location.pathname !== '/plans') ? (<>
        <Footer /> 
      </>) : ('')}

    </div>
    </div>
  );
}
export default withRouter(NonAuthLayout);


 // if(plansStatusWithoutLogin && localStorage.getItem('authorization')){
  //   return (
  //     <Suspense fallback={null}>
  //       <Navigate
  //         to={{ pathname: '/pricing' }}
  //       />
  //     </Suspense>
  //   );
  // }
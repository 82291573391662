// import React from 'react';
import React, { Suspense, useContext, useEffect } from "react";
import Sidebar from "./Sidebar";
import TopHeader from "./Top-header";
import { authRoutes } from "../../routes/allRoutes";
import "../../App.css";
import { useLocation, useNavigate, useParams, Navigate, matchPath } from "react-router-dom";
import useTitle from "../../helpers/useTitle";
import useFavIcon from "../../helpers/useFavIcon";
import { AppContext } from "../../config/context";
import { plans } from '../../config/const';
import { errorToaster } from "../Toaster";
import { ToastContainer } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlanToaster from "../PlanToaster";

function withRouter(Component) {

  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }
  return ComponentWithRouterProp;
}

const checkPermissions = (location) => {
  let currentPath = location?.pathname;
  let matchPathName;
  //check user plan by reading from local storge
  let loginUser = JSON.parse(localStorage.getItem("userDetails"))

  //compare the user plan with consts plans
  //if permisisons are there then retrun true otherwise false
  // return false;
}

function DashboardLayout(props) {
  const { isProductDetailId, setProductDetailId, setProductDetailTeam } = useContext(AppContext);
  const defaultLocation = useLocation();
  let navigate = useNavigate();
  const currentEndpoint = defaultLocation.pathname.substring(1);
  let arr = currentEndpoint.split('/');
  let token = localStorage.getItem("authorization");

  if (!token && arr[0] && arr[0] == 'productDetail' && arr[1]) {
    setProductDetailId(arr[1]);
  }
  if (!token && arr[0] && arr[0] == 'productDetail' && arr[1] && arr[2]) {
    setProductDetailTeam(arr[2]);
  }
  
  if (!localStorage.getItem('authorization')) {
    return (
      <Suspense fallback={null}>
        <Navigate
          to={{ pathname: '/', state: { from: props.location } }}
        />
      </Suspense>
    );
  }

  let permissionValue = checkPermissions(defaultLocation)

  return (
    <>
      <ToastContainer autoClose={1000} />
      <div className="PageWrapper">
        {currentEndpoint !== 'plans' && <>
          <Sidebar />
          <div className="ContentWrap" id="ContentWrap">
            <TopHeader />
            {props.element}
          </div>
        </>
        }
        {(currentEndpoint === 'plans' || currentEndpoint === 'termsOfService') && <>
          {props.element}
        </>
        }
      </div>
    </>
  );
}
export default withRouter(DashboardLayout);



  // if (permissionValue === false) {
  //   // toast.error('You allowed');
  //   // setTimeout(() => {
  //   //   toast.dismiss();
  //   // }, 1000);
  //   return (
  //     <>
  //      {/* <Suspense fallback={null}>
  //       <Navigate
  //         to={{ pathname: '/dashboard', state: { from: props.location } }}
  //       />
  //     </Suspense> */}
  //     </>    
  //   );
  // }

  // check permissions
  // if (!checkPermissions(defaultLocation)) {
  //   errorToaster('You are not allowed.');
  //   // return false;
  // }

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import axios from "axios";
import pdf from "../assets/icons/wall-icon/pdf-file-01.svg";
import xls from "../assets/icons/wall-icon/xls-file-01.svg";
import doc from "../assets/icons/wall-icon/doc-file-01.svg";
import tiff from "../assets/icons/wall-icon/tiff-file.svg";
import pptx from "../assets/icons/wall-icon/pptx.svg";
import file from "../assets/icons/wall-icon/file-file-01.svg";
import { STORAGE_BUCKET } from "../config/endPoints";
import { toast } from "react-toastify";

// Bugsnag.start({
//   // apiKey: process.env.BUGSNAG_API_KEY,
//   apiKey: "d53caf18226b59bb1f6fe5abf72c2241",
//   plugins: [new BugsnagPluginReact()]
// });

export const fileName = (url) => {
  return url.substring(url.lastIndexOf("/") + 1);
};

export const checkEffectivePermissions = (teamID) => {
  let userDetails = JSON.parse(localStorage.getItem("userDetails"));
};

export const dismissToster = () => {
  setTimeout(() => {
    toast.dismiss();
  }, 1000);
};

//Added Kamlesh 3 June 2024
export const getFileExtension = (url) => {
  if (!url) return "";
  const parts = url?.split(".");
  if (parts?.length === 1) return "";
  return parts?.pop().toLowerCase();
};

export const getVideoExtension = (url) => {
  const extArr = [
    "webm",
    "mkv",
    "flv",
    "vob",
    "ogv",
    "ogg",
    "drc",
    "gif",
    "gifv",
    "mng",
    "avi",
    "MTS",
    "M2TS",
    "TS",
    "mov",
    "qt",
    "wmv",
    "yuv",
    "rm",
    "vlv",
    "asf",
    "amv",
    "mp4",
    "mp2",
    "mpeg",
    "mpe",
    "mpv",
    "mpg",
    "mpeg",
    "m4v",
    "svi",
    "nsv",
    "flv",
    "f4v",
    "f4p",
  ];

  let filename = url?.split('/').pop();

  let extension = filename?.split('.').pop();

  const isExits = extArr?.includes(extension);
  
  return isExits;
  
};

export const checkExt = (item) => {
  if (item) {
    let ext = item.substring(item.lastIndexOf(".") + 1, item.length) || item;
    if (ext === "pdf") return pdf;
    if (ext === "doc") return doc;
    if (ext === "pptx") return pptx;
    if (ext === "tif") return tiff;
    if (ext === "xlss") return xls;
    if (ext === "png") return STORAGE_BUCKET + item;
    if (ext === "jpeg") return STORAGE_BUCKET + item;
    if (ext === "jpg") return STORAGE_BUCKET + item;
    if (ext === "JPG") return STORAGE_BUCKET + item;
    if (ext === "jpe") return STORAGE_BUCKET + item;
    if (ext === "tiff") return STORAGE_BUCKET + item;
    if (ext === "jif") return STORAGE_BUCKET + item;
    if (ext === "jfif") return STORAGE_BUCKET + item;
    if (ext === "jfi") return STORAGE_BUCKET + item;
    if (ext === "gif") return STORAGE_BUCKET + item;
    if (ext === "svg") return STORAGE_BUCKET + item;
    if (ext === "webp") return STORAGE_BUCKET + item;
    if (ext === "heic") return STORAGE_BUCKET + item;
    if (ext === "HEIC") return STORAGE_BUCKET + item;
    else return file;
  }
};

/**
 * add prefix th_ for images and changes extention to webp.
 */
export const addThumbPrefix = (fileURl) => {
  const checkExt = [
    ".jpeg",
    ".png",
    ".jpg",
    ".svg",
    ".gif",
    ".jif",
    ".jfif",
    ".jpe",
    ".jfi",
    ".webp",
    ".JPEG",
    ".PNG",
    ".JPG",
    ".SVG",
    ".GIF",
    ".JIF",
    ".JFIF",
    ".JPE",
    ".JFI",
    ".WEBP",
    ".HEIC",
    ".heic",
  ];

  let beforeExtn = fileURl.substring(0, fileURl.lastIndexOf("."));
  let extn = fileURl.substring(fileURl.lastIndexOf("."));
  if (checkExt.includes(extn)) {
    let fileURl2 = beforeExtn + ".webp";
    let url = fileURl2;
    let imageName = "th_" + url?.split("/").pop();
    let result = fileURl2?.substring(0, fileURl2?.length - imageName?.length + 3);
    // console.log("Check url111: ", result + imageName);
    return result + imageName;
  } else {
    return fileURl;
  }
};

/**
 * add prefix pre_ for images and changes extention to webp.
 */
export const addPreviewPrefix = (fileURl) => {
  const checkExt = [
    ".jpeg",
    ".png",
    ".jpg",
    ".svg",
    ".gif",
    ".jif",
    ".jfif",
    ".jpe",
    ".jfi",
    ".webp",
    ".JPEG",
    ".PNG",
    ".JPG",
    ".SVG",
    ".GIF",
    ".JIF",
    ".JFIF",
    ".JPE",
    ".JFI",
    ".WEBP",
    ".HEIC",
    ".heic",
  ];

  let beforeExtn = fileURl.substring(0, fileURl.lastIndexOf("."));
  let extn = fileURl.substring(fileURl.lastIndexOf("."));

  if (checkExt.includes(extn)) {
    let fileURl2 = beforeExtn + ".webp";
    let url = fileURl2;
    let imageName = "pre_" + url?.split("/").pop();
    let result = fileURl2?.substring(0, fileURl2?.length - imageName?.length + 4);
    return result + imageName;
  } else {
    return fileURl;
  }
};

export const getCommonHeaders = () => {
  const headers = {
    Accept: "application/json",

    // "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Origin": "*",
    //     "Access-Control-Allow-Methods": "*" ,
    authorization:
      localStorage.getItem("authorization") != null
        ? localStorage.getItem("authorization")
        : "",
  };
  return headers;
};

export const httpGet = async (url) => {
  return axios
    .get(url, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      // logout user if token expired or invalid token
      if (
        err.response.data?.responseMessage?.toLowerCase() === "invalid token" ||
        err.response.data?.responseMessage === "jwt expired"
      ) {
        localStorage.removeItem("authorization");
        window.location.href = "/";
      }
      return err.res;
    });
};

export const httpDelete = async (url) => {
  return axios
    .delete(url, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      return err.res;
    });
};

export const httpPost = async (url, body) => {
  return axios
    .post(url, body, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      return err.response;
    });
};

export const httpPut = async (url, body) => {
  return axios
    .put(url, body, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      return err.res;
    });
};

export const httpPatch = async (url, body) => {
  return axios
    .patch(url, body, {
      headers: getCommonHeaders(),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      return err.res;
    });
};

export const httpPostFormData = async (url, body) => {
  let commonHeaders = getCommonHeaders();
  delete commonHeaders.Accept;

  return axios
    .post(url, body, {
      headers: commonHeaders,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      return err.response;
    });
};

export const httpPutFormData = async (url, body) => {
  let commonHeaders = getCommonHeaders();
  delete commonHeaders.Accept;

  return axios
    .put(url, body, {
      headers: commonHeaders,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Bugsnag.notify(err);
      return err.response;
    });
};

export const _checkAndReturnPermission = async (teamPermissions, type) => {
  // console.log("Inside else 2 teamPermissions: ", teamPermissions);
  const type22 = [type];
  const filteredPermissions = Object.fromEntries(
    Object.entries(teamPermissions).filter(([key, value]) =>
      type22.includes(key)
    )
  );
  // console.log("IResult: ", filteredPermissions);

  //   const filteredPermissions22 = Object.fromEntries(
  //     Object.entries(filteredPermissions).filter(([key, value]) => type22.includes(key))
  // );

  const findKeyByValue = (object, value) => {
    for (const key in object) {
      if (object[key] === value) {
        return key;
      }
    }
    return null; // If value not found
  };

  const valueToFind = true;
  const keyAssociatedWithValue = findKeyByValue(
    filteredPermissions,
    valueToFind
  );
  // console.log("IResult222: ", keyAssociatedWithValue);
  return keyAssociatedWithValue ? true : false;
};

export const FULL_NAME = 'Full name is required.';
export const FULL_NAME_VALIDATION = 'Must be 15 characters or less.';
export const EMAIL_REQ = 'Email is required.';
export const EMAIL_VALIDATION = 'Invalid email address.';
export const OTP_VALIDATION = 'Please enter correct otp.';
export const TOGGLE = 'You must accept the Terms and Privacy Policy.';
export const NEW_PASSWORD = 'Please enter your password.';
export const PASSWORD_LENGTH = 'Password must be 8 characters long.';
export const PASSWORD_REQ = 'Password is required.';
export const ENTER_NEW_PASSWORD = 'Enter new password';
export const ENTER_CONFM_PASSWORD = 'Confirm your password.';
export const PASSWORD_VALIDATION = 'Please enter password in valid format.';
export const CONFIRM_PASSWORD = 'Confirm password is required.';
export const CONFIRM_PASSWORD_MATCH = 'Passwords must match.';
export const CATEGORY_NAME = 'Categorgy name is required.';
export const SUB_CATEGORY_NAME = 'Sub Categorgy name is required.';
export const CATEGORY_ADDED = 'Categorgy added successfully.';
export const CATEGORY_UPDATED = 'Categorgy updated successfully.';
export const CATEGORY_DELETED = 'Categorgy deleted successfully.';
export const SUB_CATEGORY_ADDED = 'Sub Categorgy added successfully.';
export const SUB_CATEGORY_UPDATED = 'Sub Categorgy updated successfully.';
export const SUB_CATEGORY_DELETED = 'Sub Categorgy deleted successfully.';
export const FABRIC_NAME = 'Fabric name is required.';
export const FABRIC_ADDED = 'Fabric has been added successfully. ';
export const FABRIC_UPDATED = 'Fabric has been updated successfully.';
export const FABRIC_DELECTED = 'Fabric has been delected successfully.';
export const SEASON_NAME = 'Season name is required.';
export const SEASON_ADDED = 'Season added successfully.';
export const SEASON_UPDATED = 'Season has been updated successfully.';
export const SEASON_DELECTED = 'Season has been deleted successfully.';
export const TAG_NAME = 'Tag name is required.';
export const TAG_ADDED = 'Tag has been added successfully.';
export const TAG_UPDATED = 'Tag has been updated successfully.';
export const TAG_DELECTED = 'Tag has been delected successfully.';
export const FILE_REQUIRED = 'File required.';
export const NAME_REQUIRED = 'Name is required.';
export const COLOR_CODE = 'Code is required.';
export const COLOR_HEX_CODE = 'Hex Code is required.';
export const COLOR_CODE_VALIDATION = 'Enter correct Hex Code.';
export const COLOR_ADDED = 'Color has been added successfully.';
export const COLOR_UPDATED = 'Color Updated successfully.';
export const COLOR_DELECTED = 'Colour deleted successfully';
export const DESC_REQUIRED = 'Description is required.';
export const DESC_CHARACTER_REQ = 'Max 160 characters allowed.';
export const TEAM_ADDED = 'Team added successfully.';
export const PLAN_EXPIRE = 'Upgrade your plan.';
export const TEAM_UPDATED = 'Team has been updated successfully.';
export const TEAM_DELECTED = 'Team has been deleted successfully.';
export const PENDING_INVITE_DELECTED = 'Pending invite has been deleted successfully.';
export const TEAM_NAME = 'Team name is required.';
export const TEAM_NAME_LENGTH = 'Team Name should not increase 20 characters';
export const TEAM_MEMBER = 'Team member is required.';
export const DUPLICATE_PRODUCT_ADDED = 'Successfully added duplicate product.';
export const ROLE_ADDED = 'Role has been added successfully.';
export const ROLE_UPDATED = 'Role has been updated successfully.';
export const ROLE_DELECTED = 'Role deleted successfullty.';
export const ROLE_NAME = 'Role name is required.';
export const JOB_TITLE = 'Job title is required.';
export const EMAIL_SETTINGS = 'Email settings are already updated.';
export const GENERAL_SETTINGS = 'General settings are already updated.';
export const PERSONAL_SETTING = 'Personal settings are already updated.';
export const TITLE_ERROR = 'Please  select a role.';
export const ADD_EXISTING_MEMBER = 'User has been added successfully.';
export const USER_BLOCKED_STATUS = 'User has been Updated successfully.';
export const NO_RECORD_FOUND = 'No Record Found.';
export const SERVICE_ID = 'Email is required.';
export const PORT_REQUIRED = 'Port is required.';
export const HOST_REQUIRED = 'Host is required.';
export const PHONE_VALIDATION = 'Phone number is not valid.';
export const FILE_SIZE_MESSAGE = 'You can’t send more than 2 GB at a time.';
export const DUPLICACY_MESSAGE =
  'We cant Upload multiple files with the same name. Please rename and try again.';
export const NO_NOTIFICATIONS = 'No new notifications.';
export const COMMENT = 'Please enter your comment';
export const PRODUCT_ADDED = 'Product added successfully.';
export const PRODUCT_UPDATED = 'Product Updated successfully.';
export const DELETE_PRODUCT_MESSAGE =
  'Are you sure you want to permanently delete this product.';
export const DELETE_POPUP_TITLE = 'Delete permanently';
export const DELETE_PRODUCT_SUCCESS = 'Product has been deleted Successfully.';
export const TEAM_INVITATION_SUCCESS = 'Invitation has been sent.';
export const UPDATE_TEAM_USER_SUCCESS = 'User has been updated successfully.';
export const PERMISSION_ALERT = 'Only Team Owner can change permission.';
export const SELECT_USER_ALERT = 'Please select a user.';
export const PRODUCT_VIEW_PERMISSION =
  'Please check permission according to team.';
export const SELECT_TEAM_TEXT = 'Please select a team.';
export const PRODUCT_ADD_PERMISSION =
  'You donot have permission to view this product.';
export const PRODUCT_WISHLIST_PERMISSION =
  'You donot have permission to like this product.';
export const PRODUCT_SHARE_PERMISSION =
  'You donot have permission to share this product.';
export const TRY_AGAIN = 'Please try again.';
export const REMINDER_ADDED = 'Reminder added successfully.';
export const LINKEDiN_VALIDATION = 'Linkedin url is incorrect.';
export const DEACTIVATE_ACCOUNT = 'Your account has been Deactivated.';
export const SUBSCRIBED_SUCCESSFULLY = 'Subscribed successfully.';
export const ASK_FOR_PERMISSION = 'Ask for permission.';
export const DELETE_REMINDER = 'Do you want to delete reminder?';
export const PERMISSIONS_UPDATED = 'Permissions updated successfully.';

import "./App.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import NonAuthLayout from "./components/NonAuthLayout";
import { authRoutes, dashboardRoutes, publicRoutes } from "./routes/allRoutes";
import DashboardLayout from "./components/DashboardLayout";
import loaderImg from "./assets/images/loader.gif";
import { AppContext } from "./config/context";
import { Provider } from "react-redux";
import store from "./store";
Bugsnag.start({
  // apiKey: process.env.BUGSNAG_API_KEY,
  apiKey: "d53caf18226b59bb1f6fe5abf72c2241",
  plugins: [new BugsnagPluginReact()],
});
// Create the error boundary...
// const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

// const ErrorView = () =>
//   <div>
//     <p>Inform users of an error in the component tree.</p>
//   </div>

// const onError = (event) => {
//   // callback will only run for errors caught by boundary
// }

// const ErrorView = ({ clearError }) =>
//   <div>
//     <p>Inform users of an error in the component tree.
//     Use clearError to reset ErrorBoundary state and re-render child tree.</p>
//     <button onClick={clearError}>Reset</button>
//   </div>

const App = (props) => {
  const defaultLocation = useLocation();
  const [teamApi, setTeamApi] = useState(false);
  const [UserInfoContext, setUserInfoContext] = useState();
  const [createProductStatus, setCreateProductStatus] = useState(false);
  const [query, setQuery] = useState("");
  const [isProductDetailId, setProductDetailId] = useState(null);
  const [isProductDetailTeam, setProductDetailTeam] = useState(null);
  const [teamIdForPermission, setTeamIdForPermission] = useState();
  const [permissionStatus, setPermissionStatus] = useState(false);
  const [plansStatusWithoutLogin, setPlansStatusWithoutLogin] = useState(false);
  const [selectedPlanBeforeLogin, setSelectedPlanBeforeLogin] = useState();
  const [globalSearchStatus, setGlobalSearchStatus] = useState(false);

  useEffect(() => {
    if (defaultLocation.pathname !== "/wall") {
      setTeamIdForPermission();
    }
    if (defaultLocation.pathname !== "/globalSearch") {
      setGlobalSearchStatus(false);
    }
  }, [defaultLocation]);

  function getLayout(element, layout) {
    if (layout == "NonAuthLayout") {
      return <NonAuthLayout element={element} />;
    }
    return <DashboardLayout element={element} />;
  }
  // Bugsnag.notify(new Error('Test error'));
  return (
    <Provider store={store}>
      <AppContext.Provider
        value={{
          teamApi,
          setTeamApi,
          UserInfoContext,
          setUserInfoContext,
          createProductStatus,
          setCreateProductStatus,
          query,
          setQuery,
          setProductDetailId,
          isProductDetailId,
          teamIdForPermission,
          setTeamIdForPermission,
          permissionStatus,
          setPermissionStatus,
          isProductDetailTeam,
          setProductDetailTeam,
          plansStatusWithoutLogin,
          setPlansStatusWithoutLogin,
          selectedPlanBeforeLogin,
          setSelectedPlanBeforeLogin,
          globalSearchStatus,
          setGlobalSearchStatus,
        }}
      >
        <React.Fragment>
          <Suspense
            fallback={
              <img
                src={loaderImg}
                style={{
                  position: "absolute",
                  transform: "translate(-50%,-50%)",
                  left: "50%",
                  top: "50%",
                  color: "blue",
                  fontSize: "20px",
                  fontWeight: "bold",
                  fontFamily: "'Rubik', sans-serif",
                }}
              />
            }
          >
            <Routes>
              {authRoutes.map((route, idx) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={getLayout(route.element, "NonAuthLayout")}
                />
              ))}

              {publicRoutes.map((route, idx) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={getLayout(route.element, "NonAuthLayout")}
                />
              ))}

              {dashboardRoutes.map((route, idx) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={getLayout(route.element, "DashboardLayout")}
                />
              ))}
            </Routes>
          </Suspense>
        </React.Fragment>
      </AppContext.Provider>
    </Provider>
  );
};

export default App;
